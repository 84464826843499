import { Link } from 'gatsby';
import styled from 'styled-components';

export const MainDiv = styled.div`
  z-index: 100;
  right: 4px;
  bottom: 62px;
  position: absolute;
  width: 100%;
  cursor: default;
  display: block;
  font: 400 11px Roboto, Arial, sans-serif;
  ::after {
    background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 0) 51%,
        rgba(255, 255, 255, 0) 100%
      )
      #fff;
    box-shadow: -2px 2px 2px 0 rgb(178 178 178 / 40%);
    content: '';
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 15px;
  }
`;
export const InnerDiv = styled.div`
  position: absolute;
  box-sizing: border-box;
  overflow: hidden;
  top: 0;
  left: 0;
  transform: translate3d(-50%, -100%, 0);
  background-color: white;
  padding: 12px;
  max-width: 648px;
  max-height: 613px;
  min-width: 0px;
  font-weight: 300;
  font-size: 13px;
  @media (max-width: 991px) {
    left: 0px;
    transform: translate3d(-50%, -100%, 0px);
  }
  @media (max-width: 767px) {
    overflow: scroll;
    max-width: 250px;
    left: 0px;
    transform: translate3d(-50%, -100%, 0px);
  }
`;

export const PopupCloseStyle = styled.div`
  background: none;
  display: block;
  border: 0px;
  margin: 0px;
  padding: 0px;
  text-transform: none;
  appearance: none;
  position: absolute;
  cursor: pointer;
  user-select: none;
  top: 0;
  right: -6px;
  width: 30px;
  height: 30px;
  :hover {
    opacity: 0.6;
  }
  @media (max-width: 767px) {
    right: -20px;
  }
`;

export const InfoWindowStyle = styled.div`
  width: 500px;
  font-family: 'Figtree', arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  @media (max-width: 767px) {
    float: none;
    max-width: 177px;
    max-height: 313px;
    border-radius: 8px;
    padding: 12px;
  }
`;
export const InfoWindowLeft = styled.div`
  float: left;
  width: 50%;
  padding: 25px;
  @media (max-width: 767px) {
    float: none;
    width: 100%;
    padding: 15px;
  }
`;
export const InfoWindowH2 = styled.h2`
  margin-bottom: 5px;
  font-family: 'Adelle-Bold', arial, sans-serif !important;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  color: #0e4c80;
`;

export const ParaText = styled.p`
  margin-bottom: 15px !important;
`;
export const InfoWindowEm = styled.em`
  display: block;
  color: #134b85;
`;

export const AnchorStyle = styled.a`
  display: block;
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  :hover {
    text-decoration: none;
    color: #0e4c80;
  }
`;

export const InfoWindowRight = styled.div`
  float: left;
  width: 50%;
  padding: 25px 25px 25px 0;
  @media (max-width: 767px) {
    width: 100%;
    float: none;
    padding: 15px;
  }
`;

export const InfoWindowH3 = styled.h3`
  margin-bottom: 10px;
  font-family: 'Figtree-Bold', arial, sans-serif;
  font-size: 16px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
`;

export const LinkStyle = styled(Link)`
  text-decoration: none;
  color: #55aa4c;
  transition: 0.25s ease-out;
  :hover {
    text-decoration: none;
    color: #0e4c80;
  }
`;
